import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "utils/UserContext";

import {
  Badge,
  Box,
  Center,
  Flex,
  Loader,
  Paper,
  Pagination,
  Stack,
  Tabs,
  Text,
  useMantineTheme,
} from "@mantine/core";

import { useAppDispatch } from "reduxStore/hooks";
import { addNewCreatorSetToState } from "reduxStore/creatorSetsSlice";

import {
  Icon,
  IconBookmarks,
  IconCircleCheck,
  IconPlaystationX,
  IconProps,
} from "@tabler/icons-react";

import { ActiveTab, CreatorSetEntryStates } from "components/discovery/search/Common";
import {
  CreatorDetails,
  CreatorSetEntryState,
  CreatorSetMembershipDetails,
} from "components/discovery/Datamodels";

import { getAccessibleCreatorSets } from "components/discovery/search/Api";

import { fetchCreatorSetDetails } from "components/creator_sets/CreatorSetUtils";

import { AddCreatorModalToCreatorSet } from "components/campaign/dashboard/CreatorDetailsForms";
import UnifiedCreatorRep from "components/creator_lists/unified_creator_rep/UnifiedCreatorRep";
import CreatorResultControls from "components/discovery/search/CreatorResultControls";
import { CreatorSetEmptyState } from "components/creator_sets/SetManagementView";
import CreatorSetDropdown from "components/creator_sets/CreatorSetDropdown";
import { useCampaignAppContext } from "campaigns/CampaignAppShell";
import Spacer from "components/Spacer";

const DEFAULT_PAGE_SIZE = 25;

const CreatorSetEntryImport = ({
  creatorSetId,
  creatorSetOptions,
  creatorIds,
  setCreatorIds,
  creatorSetDetails,
  setCreatorSetDetails,
  creatorSetEntryStates,
  setCreatorSetEntryStates,
  isStaff,
}: {
  creatorSetId: number;
  creatorSetOptions: Record<string, string>[];
  creatorIds: number[];
  setCreatorIds: (creatorIds: number[]) => void;
  creatorSetDetails: Record<number, CreatorDetails>;
  setCreatorSetDetails: (creatorSetDetails: Record<number, CreatorDetails>) => void;
  creatorSetEntryStates: CreatorSetEntryStates;
  setCreatorSetEntryStates: (creatorSetEntryStates: CreatorSetEntryStates) => void;
  isStaff: boolean;
}) => {
  const handleAddOrUpdateCreatorSetEntry = (
    creatorDetails: CreatorDetails,
    selectedCreatorSetId: number,
    creatorSetState: CreatorSetEntryState,
  ) => {
    const creatorId = creatorDetails.creator_id;
    const newCreatorSetEntryStates = { ...creatorSetEntryStates };

    // Ensure selectedCreatorSetId entry exists
    if (!newCreatorSetEntryStates[selectedCreatorSetId]) {
      newCreatorSetEntryStates[selectedCreatorSetId] = {};
    }

    // Remove Creator ID from all existing states
    Object.values(CreatorSetEntryState).forEach((state: CreatorSetEntryState) => {
      if (newCreatorSetEntryStates[selectedCreatorSetId][state]) {
        newCreatorSetEntryStates[selectedCreatorSetId][state].delete(creatorId);
      }
    });

    // Ensure state set exists
    if (!newCreatorSetEntryStates[selectedCreatorSetId][creatorSetState]) {
      newCreatorSetEntryStates[selectedCreatorSetId][creatorSetState] = new Set<number>();
    }

    // Add to state set
    newCreatorSetEntryStates[selectedCreatorSetId][creatorSetState].add(creatorId);

    // Update State
    setCreatorSetEntryStates(newCreatorSetEntryStates);

    // Update creatorIds
    const newCreatorIds = creatorIds.filter((id) => id !== creatorId);
    setCreatorIds([creatorId, ...newCreatorIds]);

    // Update creatorSetDetails
    setCreatorSetDetails({ ...creatorSetDetails, [creatorId]: creatorDetails });
  };

  return (
    <AddCreatorModalToCreatorSet
      creatorSetId={creatorSetId}
      creatorSetOptions={creatorSetOptions}
      handleAddOrUpdateCreatorSetEntry={handleAddOrUpdateCreatorSetEntry}
      isStaff={isStaff}
    />
  );
};

const getCreatorSetMembershipDetails = (
  creator: CreatorDetails,
  creatorSetOptions: Record<string, string>[],
  creatorSetEntryStates: CreatorSetEntryStates,
) => {
  return creatorSetOptions.map((creatorSetInfo) => {
    const creatorSetId = parseInt(creatorSetInfo.value, 10);
    const creatorId = creator.creator_id;

    return {
      creatorId,
      creatorSetId,
      name: creatorSetInfo.label,
      isSaved:
        creatorSetEntryStates[creatorSetId]?.[CreatorSetEntryState.SAVED]?.has(creatorId) ?? false,
      isActivated:
        creatorSetEntryStates[creatorSetId]?.[CreatorSetEntryState.ACCEPTED]?.has(creatorId) ??
        false,
    } as CreatorSetMembershipDetails;
  });
};

const parseCreatorSetEntryStates = (response: any) => {
  const deserializedCreatorSetToCreators: CreatorSetEntryStates = {};
  Object.entries(response).forEach(([creatorSetId, creatorSetEntries]) => {
    const deserializedCreatorSetEntries: Partial<Record<CreatorSetEntryState, Set<number>>> = {};

    Object.entries(creatorSetEntries).forEach(([entryState, memberCreatorIds]) => {
      const stateKey = Number(entryState) as CreatorSetEntryState;
      if (Array.isArray(memberCreatorIds)) {
        deserializedCreatorSetEntries[stateKey] = new Set<number>(memberCreatorIds);
      }
    });

    deserializedCreatorSetToCreators[Number(creatorSetId)] =
      deserializedCreatorSetEntries as Record<CreatorSetEntryState, Set<number>>;
  });
  return deserializedCreatorSetToCreators;
};

function CreatorFeed({
  creatorIds,
  loadingResults,
  creatorSetDetails,
  setCreatorSetDetails,
  creatorSetOptions,
  creatorSetEntryStates,
  activateCreatorId,
  saveCreatorId,
  unsaveCreatorId,
  rejectCreatorId,
}: {
  creatorIds: number[];
  loadingResults: boolean;
  creatorSetDetails: Record<number, CreatorDetails>;
  setCreatorSetDetails: (value: Record<number, CreatorDetails>) => void;
  creatorSetOptions: Record<string, string>[];
  creatorSetEntryStates: CreatorSetEntryStates;
  activateCreatorId: (creatorId: number, creatorSetId: number) => void;
  saveCreatorId: (creatorId: number, creatorSetId: number) => void;
  unsaveCreatorId: (creatorId: number, creatorSetId: number) => void;
  rejectCreatorId: (creatorId: number, creatorSetId: number) => void;
}) {
  const [currentPageCreatorIds, setCurrentPageCreatorIds] = useState<number[]>([]);

  const [activePage, setActivePage] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);

  const totalNumPages =
    creatorIds && creatorIds.length > 0
      ? Math.floor(creatorIds.length / DEFAULT_PAGE_SIZE) +
        (creatorIds.length % DEFAULT_PAGE_SIZE === 0 ? 0 : 1)
      : 0;

  const handlePageUpdate = (newPageNumber: number) => {
    setPageLoading(true);
    setActivePage(newPageNumber);

    const newPageCreatorIds = creatorIds.slice(
      (newPageNumber - 1) * DEFAULT_PAGE_SIZE,
      newPageNumber * DEFAULT_PAGE_SIZE,
    );

    const abortController = new AbortController();
    fetchCreatorSetDetails(
      newPageCreatorIds,
      creatorSetDetails,
      setCreatorSetDetails,
      abortController,
    ).then(() => {
      setCurrentPageCreatorIds(newPageCreatorIds);
      setPageLoading(false);
    });
  };

  useEffect(() => {
    if (creatorIds && creatorIds.length > 0) {
      handlePageUpdate(1);
    }
  }, [creatorIds]);

  const creatorContent = currentPageCreatorIds.map((currentCreatorId) => {
    const creatorSetMembershipDetails = getCreatorSetMembershipDetails(
      creatorSetDetails[currentCreatorId],
      creatorSetOptions,
      creatorSetEntryStates,
    );

    return (
      <UnifiedCreatorRep
        key={currentCreatorId}
        creator={creatorSetDetails[currentCreatorId]}
        defaultExpandedPlatforms={["tiktok", "youtube", "instagram"]}
        creatorSetControls={
          <CreatorResultControls
            creatorId={currentCreatorId}
            creatorSetMembershipDetails={creatorSetMembershipDetails}
            hiddenCreatorIdsSet={new Set()}
            handleActivate={activateCreatorId}
            handleSave={saveCreatorId}
            handleUnsave={unsaveCreatorId}
            handleHide={() => {}}
            handleUnhide={() => {}}
            handleReject={rejectCreatorId}
          />
        }
        refetchThumbnails
      />
    );
  });

  return (
    <Stack>
      {!pageLoading ? creatorContent : null}
      {loadingResults || pageLoading ? (
        <Center mt="xs">
          <Loader type="dots" />
        </Center>
      ) : null}
      {!loadingResults && creatorIds && creatorIds.length > 0 && (
        <Center>
          <Pagination
            value={activePage}
            onChange={(newPageNumber: number) => handlePageUpdate(newPageNumber)}
            total={totalNumPages}
          />
        </Center>
      )}
    </Stack>
  );
}

const CreatorTabLabel = ({
  badgeCount,
  IconName,
  isActive,
  title,
}: {
  badgeCount: number;
  IconName: React.ForwardRefExoticComponent<Omit<IconProps, "ref"> & React.RefAttributes<Icon>>;
  isActive: boolean;
  title: string;
}) => (
  <Flex align="center" style={{ gap: 8 }}>
    <IconName
      size="1rem"
      color={isActive ? "var(--mantine-color-blue-6" : "var(--mantine-color-dark-8)"}
    />
    <Text fw="500" c={isActive ? "var(--mantine-color-blue-6" : "black"}>
      {title}
    </Text>
    <Badge
      size="xs"
      radius="xl"
      circle={badgeCount < 10}
      color={
        badgeCount > 0 && isActive ? "var(--mantine-color-blue-6" : "var(--mantine-color-dark-4)"
      }>
      {badgeCount}
    </Badge>
  </Flex>
);

export default function CreatorSetView() {
  const { creatorSetId: selectedCreatorSetId } = useParams<{ creatorSetId: string }>();
  const [currentUser] = useUser();
  const isStaff = currentUser?.is_staff ?? false;

  const [loadingResults, setLoadingResults] = useState(true);
  const [activeTab, setActiveTab] = useState<CreatorSetEntryState>(CreatorSetEntryState.ACCEPTED);

  const [creatorIds, setCreatorIds] = useState<number[]>([]);
  const [activatedCreatorIds, setActivatedCreatorIds] = useState<number[]>([]);
  const [savedCreatorIds, setSavedCreatorIds] = useState<number[]>([]);
  const [rejectedCreatorIds, setRejectedCreatorIds] = useState<number[]>([]);
  const [creatorSetDetails, setCreatorSetDetails] = useState<Record<number, CreatorDetails>>({});

  const [creatorSetOptions, setCreatorSetOptions] = useState<Record<string, string>[]>([]);
  const [creatorSetEntryStates, setCreatorSetEntryStates] = useState<CreatorSetEntryStates>(null);

  const theme = useMantineTheme();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!creatorIds || !creatorSetEntryStates) {
      return;
    }

    const activatedIds = Object.values(creatorSetEntryStates).reduce((acc, entries) => {
      if (entries[CreatorSetEntryState.ACCEPTED]) {
        return new Set([...acc, ...Array.from(entries[CreatorSetEntryState.ACCEPTED])]);
      }
      return acc;
    }, new Set<number>());

    const savedIds = Object.values(creatorSetEntryStates).reduce((acc, entries) => {
      if (entries[CreatorSetEntryState.SAVED]) {
        return new Set([...acc, ...Array.from(entries[CreatorSetEntryState.SAVED])]);
      }
      return acc;
    }, new Set<number>());

    const rejectedIds = Object.values(creatorSetEntryStates).reduce((acc, entries) => {
      if (entries[CreatorSetEntryState.REJECTED]) {
        return new Set([...acc, ...Array.from(entries[CreatorSetEntryState.REJECTED])]);
      }
      return acc;
    }, new Set<number>());

    setActivatedCreatorIds(creatorIds.filter((id) => activatedIds.has(id)));
    setSavedCreatorIds(creatorIds.filter((id) => savedIds.has(id)));
    setRejectedCreatorIds(Array.from(rejectedIds));
  }, [creatorIds, creatorSetEntryStates]);

  useEffect(() => {
    getAccessibleCreatorSets(true, Number(selectedCreatorSetId))
      .then((response) => {
        const { results, creatorSetToCreators, sortedCreatorIds } = response;
        setCreatorSetOptions(results);

        const deserializedCreatorSetToCreators = parseCreatorSetEntryStates(creatorSetToCreators);
        setCreatorSetEntryStates(deserializedCreatorSetToCreators);

        // Filter out creator sets that are not the selected creator set
        if (selectedCreatorSetId) {
          // Include ACCEPTED and SAVED
          const sortedSelectedCreatorIds = sortedCreatorIds.filter((candidateCreatorId: number) => {
            return (
              deserializedCreatorSetToCreators[Number(selectedCreatorSetId)]?.[
                CreatorSetEntryState.ACCEPTED
              ]?.has(candidateCreatorId) ||
              deserializedCreatorSetToCreators[Number(selectedCreatorSetId)]?.[
                CreatorSetEntryState.SAVED
              ]?.has(candidateCreatorId)
            );
          });
          setCreatorIds(sortedSelectedCreatorIds);
        } else {
          setCreatorIds(sortedCreatorIds);
        }
      })
      .finally(() => setLoadingResults(false));
  }, [selectedCreatorSetId]);

  const saveCreatorId = (creatorId: number, creatorSetId: number) => {
    const newCreatorSetEntryStates = { ...creatorSetEntryStates };

    // Ensure creatorSetId entry exists
    if (!newCreatorSetEntryStates[creatorSetId]) {
      newCreatorSetEntryStates[creatorSetId] = {};
    }

    // Ensure SAVED set exists
    if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]) {
      newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED] = new Set<number>();
    }

    // Add to SAVED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED].add(creatorId);

    // Update State
    setCreatorSetEntryStates(newCreatorSetEntryStates);
  };

  const unsaveCreatorId = (creatorId: number, creatorSetId: number) => {
    const newCreatorSetEntryStates = { ...creatorSetEntryStates };

    // Ensure creatorSetId entry exists
    if (!newCreatorSetEntryStates[creatorSetId]) {
      newCreatorSetEntryStates[creatorSetId] = {};
    }

    // Ensure SAVED set exists
    if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]) {
      newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED] = new Set<number>();
    }

    // Remove from SAVED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED].delete(creatorId);

    setCreatorSetEntryStates(newCreatorSetEntryStates);
  };

  const activateCreatorId = (creatorId: number, creatorSetId: number) => {
    const newCreatorSetEntryStates = { ...creatorSetEntryStates };

    // Ensure creatorSetId entry exists
    if (!newCreatorSetEntryStates[creatorSetId]) {
      newCreatorSetEntryStates[creatorSetId] = {};
    }

    // Ensure ACCEPTED set exists
    if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED]) {
      newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED] = new Set<number>();
    }

    // Add to ACCEPTED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED].add(creatorId);

    // Remove from SAVED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.SAVED]?.delete(creatorId);

    // Remove from REJECTED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.REJECTED]?.delete(creatorId);

    setCreatorSetEntryStates(newCreatorSetEntryStates);
  };

  const rejectCreatorId = (creatorId: number, creatorSetId: number) => {
    const newCreatorSetEntryStates = { ...creatorSetEntryStates };

    // Ensure creatorSetId entry exists
    if (!newCreatorSetEntryStates[creatorSetId]) {
      newCreatorSetEntryStates[creatorSetId] = {};
    }

    // Remove from ACCEPTED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.ACCEPTED]?.delete(creatorId);

    // Ensure REJECTED entry exists
    if (!newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.REJECTED]) {
      newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.REJECTED] = new Set<number>();
    }
    // Add to REJECTED set
    newCreatorSetEntryStates[creatorSetId][CreatorSetEntryState.REJECTED].add(creatorId);

    setCreatorSetEntryStates(newCreatorSetEntryStates);

    setRejectedCreatorIds([...rejectedCreatorIds, creatorId]);
  };

  // show empty state if there is no selected creator set, page is not loading and user has no creator sets
  if (!loadingResults && !selectedCreatorSetId && creatorSetOptions.length === 0) {
    return (
      <CreatorSetEmptyState
        onCreatorSetCreated={(creatorSet) => {
          setCreatorSetOptions((prevOptions) => [
            ...prevOptions,
            {
              value: creatorSet.id.toString(),
              label: creatorSet.name,
            },
          ]);
          dispatch(addNewCreatorSetToState({ creatorSet }));
        }}
      />
    );
  }

  if (loadingResults) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  const shouldShowCreatorFeed =
    (activeTab === CreatorSetEntryState.ACCEPTED && activatedCreatorIds.length > 0) ||
    (activeTab === CreatorSetEntryState.SAVED && savedCreatorIds.length > 0) ||
    (activeTab === CreatorSetEntryState.REJECTED && rejectedCreatorIds.length > 0);

  const getActiveTabValue = () => {
    switch (activeTab) {
      case CreatorSetEntryState.ACCEPTED:
        return "activated";
      case CreatorSetEntryState.SAVED:
        return "saved";
      case CreatorSetEntryState.REJECTED:
        return "rejected";
      default:
        return null;
    }
  };

  const getCreatorIdsForFeed = () => {
    switch (activeTab) {
      case CreatorSetEntryState.ACCEPTED:
        return activatedCreatorIds;
      case CreatorSetEntryState.SAVED:
        return savedCreatorIds;
      case CreatorSetEntryState.REJECTED:
        return rejectedCreatorIds;
      default:
        return [];
    }
  };

  return (
    <Paper p="md">
      <CreatorSetDropdown selectedCreatorSetId={selectedCreatorSetId} />
      <Spacer height={24} />
      <Tabs
        variant="outline"
        value={getActiveTabValue()}
        onChange={(value: string) => {
          if (value === "activated") {
            setActiveTab(CreatorSetEntryState.ACCEPTED);
          } else if (value === "saved") {
            setActiveTab(CreatorSetEntryState.SAVED);
          } else if (value === "rejected") {
            setActiveTab(CreatorSetEntryState.REJECTED);
          }
        }}>
        <Flex justify="space-between">
          <Tabs.List mb="md" w="100%">
            <Tabs.Tab pl={12} pr={12} value="activated" disabled={activatedCreatorIds.length === 0}>
              <CreatorTabLabel
                IconName={IconCircleCheck}
                isActive={activeTab === CreatorSetEntryState.ACCEPTED}
                title="Activated"
                badgeCount={activatedCreatorIds.length}
              />
            </Tabs.Tab>
            <Tabs.Tab pl={12} pr={12} value="saved" disabled={savedCreatorIds.length === 0}>
              <CreatorTabLabel
                IconName={IconBookmarks}
                isActive={activeTab === CreatorSetEntryState.SAVED}
                title="Saved"
                badgeCount={savedCreatorIds.length}
              />
            </Tabs.Tab>
            <Tabs.Tab pl={12} pr={12} value="rejected" disabled={rejectedCreatorIds.length === 0}>
              <CreatorTabLabel
                IconName={IconPlaystationX}
                isActive={activeTab === CreatorSetEntryState.REJECTED}
                title="Rejected"
                badgeCount={rejectedCreatorIds.length}
              />
            </Tabs.Tab>
          </Tabs.List>
          <Box
            style={{
              borderBottom: `1px solid ${theme.colors.gray[3]}`,
            }}
            mb="md"
            pr="xs">
            <CreatorSetEntryImport
              creatorSetId={Number(selectedCreatorSetId)}
              creatorSetOptions={creatorSetOptions}
              creatorIds={creatorIds}
              setCreatorIds={setCreatorIds}
              creatorSetDetails={creatorSetDetails}
              setCreatorSetDetails={setCreatorSetDetails}
              creatorSetEntryStates={creatorSetEntryStates}
              setCreatorSetEntryStates={setCreatorSetEntryStates}
              isStaff={isStaff}
            />
          </Box>
        </Flex>
        {shouldShowCreatorFeed ? (
          <CreatorFeed
            creatorIds={getCreatorIdsForFeed()}
            loadingResults={loadingResults}
            creatorSetDetails={creatorSetDetails}
            setCreatorSetDetails={setCreatorSetDetails}
            creatorSetOptions={creatorSetOptions}
            creatorSetEntryStates={creatorSetEntryStates}
            activateCreatorId={activateCreatorId}
            saveCreatorId={saveCreatorId}
            unsaveCreatorId={unsaveCreatorId}
            rejectCreatorId={rejectCreatorId}
          />
        ) : null}
      </Tabs>
    </Paper>
  );
}
