type FilterConfigMap = {
  [key in FilterType]: {
    min: number;
    max: number;
    defaultMin: number;
    defaultMax: number;
    stepSize: number;
    marks: { value: number; label: string }[] | null;
  };
};

export enum FilterType {
  FOLLOWERS = "followers",
  MEDIAN_VIEWS = "median_views",
}

export enum CountryFilterOption {
  US = "US",
  CA = "CA",
  GB = "GB",
  AU = "AU",
  DK = "DK",
  ROW = "ROW",
}

export const CountryCodeToLabel: { [key in CountryFilterOption]: string } = {
  [CountryFilterOption.US]: "United States",
  [CountryFilterOption.CA]: "Canada",
  [CountryFilterOption.GB]: "United Kingdom",
  [CountryFilterOption.AU]: "Australia",
  [CountryFilterOption.DK]: "Denmark",
  [CountryFilterOption.ROW]: "Rest of World",
};

export const CountryCodeToFilterValue: { [key in CountryFilterOption]: string } = {
  [CountryFilterOption.US]: "US",
  [CountryFilterOption.CA]: "CA",
  [CountryFilterOption.GB]: "GB",
  [CountryFilterOption.AU]: "AU",
  [CountryFilterOption.DK]: "DK",
  [CountryFilterOption.ROW]: "Rest of World",
};

export const FilterConfigs: FilterConfigMap = {
  [FilterType.FOLLOWERS]: {
    min: 1_000,
    max: 100_000,
    defaultMin: 5_000,
    defaultMax: 100_000,
    stepSize: 1_000,
    marks: null,
  },
  [FilterType.MEDIAN_VIEWS]: {
    min: 0,
    max: 100_000,
    defaultMin: 1_000,
    defaultMax: 100_000,
    stepSize: 1_000,
    marks: null,
  },
};

export default null;
