import React, { useState } from "react";

import { Button, Divider, Flex, Loader, Menu, Text, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import {
  IconBookmarkOff,
  IconBookmarkPlus,
  IconBookmarks,
  IconCirclePlus,
  IconCheck,
  IconEye,
  IconEyeOff,
  IconPlus,
  IconUsers,
} from "@tabler/icons-react";

import { activateCreator, saveCreator, unsaveCreator } from "components/discovery/search/Api";

import { useAppDispatch } from "reduxStore/hooks";
import { addNewCreatorSetToState } from "reduxStore/creatorSetsSlice";

import { LoggingMetadata } from "components/discovery/search/Common";
import {
  CreatorSetEntryState,
  CreatorSetItem,
  CreatorSetMembershipDetails,
} from "components/discovery/Datamodels";

import { AddCreatorSetModal } from "components/creator_sets/SetManagementView";
import { updateReviewCreatorSetEntry } from "components/creator_sets/CreatorSetUtils";
import { notifications } from "@mantine/notifications";

const ActivateCreatorItem = ({
  membershipDetails,
  handleActivateCreatorId,
  loggingMetadata,
  onCreatorRejected,
}: {
  membershipDetails: CreatorSetMembershipDetails;
  handleActivateCreatorId: (creatorId: number, creatorSetId: number) => void;
  loggingMetadata?: LoggingMetadata;
  onCreatorRejected: (creatorId: number, creatorSetId: number) => void;
}) => {
  const [loading, setLoading] = useState(false);

  let icon = membershipDetails.isActivated ? <IconCheck size="1rem" /> : <IconPlus size="1rem" />;

  if (loading) {
    icon = <Loader size="1rem" />;
  }

  const handleActivate = () => {
    setLoading(true);
    activateCreator({
      sessionId: loggingMetadata?.sessionId,
      queryId: loggingMetadata?.queryId,
      sortBy: loggingMetadata?.sortBy,
      activeTab: loggingMetadata?.activeTab,
      pageNumber: loggingMetadata?.pageNumber,
      creatorId: membershipDetails.creatorId,
      creatorSetId: membershipDetails.creatorSetId,
      shouldLog: loggingMetadata !== undefined,
    })
      .then((response) => {
        const { success } = response;

        if (success) {
          handleActivateCreatorId(membershipDetails.creatorId, membershipDetails.creatorSetId);
        }
      })
      .finally(() => setLoading(false));
  };

  const rejectCreatorItem = () => {
    setLoading(true);
    updateReviewCreatorSetEntry({
      creatorId: membershipDetails.creatorId,
      creatorSetId: membershipDetails.creatorSetId,
      entryState: CreatorSetEntryState.REJECTED,
    })
      .then((response) => {
        const { success, message } = response;
        if (success) {
          onCreatorRejected(membershipDetails.creatorId, membershipDetails.creatorSetId);
        } else if (message) {
          notifications.show({
            title: "Oops! Something went wrong.",
            message,
            autoClose: 4000,
            color: "red",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Menu.Item
      c={
        membershipDetails.isActivated ? "var(--mantine-color-dimmed)" : "var(--mantine-color-text)"
      }
      leftSection={icon}
      onClick={() => {
        if (membershipDetails.isActivated) {
          rejectCreatorItem();
        } else {
          handleActivate();
        }
      }}>
      {membershipDetails.isActivated && "Activated for "}
      {membershipDetails.name}
    </Menu.Item>
  );
};

function SaveCreatorItem({
  creatorId,
  creatorSetId,
  creatorSetName,
  isActivated,
  save,
  loggingMetadata,
}: {
  creatorId: number;
  creatorSetId: number;
  creatorSetName: string;
  isActivated: boolean;
  save: () => void;
  loggingMetadata?: LoggingMetadata;
}) {
  const [loading, setLoading] = useState(false);

  let icon = isActivated ? <IconCheck size="1rem" /> : <IconBookmarkPlus size="1rem" />;

  if (loading) {
    icon = <Loader size="1rem" />;
  }

  const handleSave = () => {
    setLoading(true);
    saveCreator({
      sessionId: loggingMetadata?.sessionId,
      queryId: loggingMetadata?.queryId,
      sortBy: loggingMetadata?.sortBy,
      activeTab: loggingMetadata?.activeTab,
      pageNumber: loggingMetadata?.pageNumber,
      creatorId,
      creatorSetId,
      shouldLog: loggingMetadata !== undefined,
    })
      .then((response) => {
        const { success } = response;

        if (success) {
          save();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Menu.Item leftSection={icon} onClick={handleSave} disabled={isActivated}>
      {isActivated && "Activated in"} {creatorSetName}
    </Menu.Item>
  );
}

function UnsaveCreatorItem({
  creatorId,
  creatorSetId,
  creatorSetName,
  unsave,
  loggingMetadata,
}: {
  creatorId: number;
  creatorSetId: number;
  creatorSetName: string;
  unsave: () => void;
  loggingMetadata?: LoggingMetadata;
}) {
  const [loading, setLoading] = useState(false);

  let icon = <IconBookmarkOff size="1rem" />;

  if (loading) {
    icon = <Loader size="1rem" />;
  }

  const handleUnsave = () => {
    setLoading(true);
    unsaveCreator({
      sessionId: loggingMetadata?.sessionId,
      queryId: loggingMetadata?.queryId,
      sortBy: loggingMetadata?.sortBy,
      activeTab: loggingMetadata?.activeTab,
      pageNumber: loggingMetadata?.pageNumber,
      creatorId,
      creatorSetId,
      shouldLog: loggingMetadata !== undefined,
    })
      .then((response) => {
        const { success } = response;

        if (success) {
          unsave();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Menu.Item leftSection={icon} onClick={handleUnsave}>
      {creatorSetName}
    </Menu.Item>
  );
}

function SaveMenu({
  creatorSetMembershipDetails,
  handleSaveCreatorId,
  handleUnsaveCreatorId,
  openAddNewCreatorSetModal,
  loggingMetadata,
  handleAddCreatorSet,
}: {
  creatorSetMembershipDetails: CreatorSetMembershipDetails[];
  handleSaveCreatorId: (creatorId: number, creatorSetId: number) => void;
  handleUnsaveCreatorId: (creatorId: number, creatorSetId: number) => void;
  openAddNewCreatorSetModal: () => void;
  loggingMetadata?: LoggingMetadata;
  handleAddCreatorSet?: (creatorSetId: number, creatorSetName: string) => void;
}) {
  const saveMenuItems = creatorSetMembershipDetails
    .filter((membershipDetails) => !membershipDetails.isSaved)
    .map((membershipDetails) => (
      <SaveCreatorItem
        key={`${membershipDetails.creatorId}-${membershipDetails.creatorSetId}`}
        loggingMetadata={loggingMetadata}
        creatorId={membershipDetails.creatorId}
        creatorSetId={membershipDetails.creatorSetId}
        creatorSetName={membershipDetails.name}
        isActivated={membershipDetails.isActivated}
        save={() =>
          handleSaveCreatorId(membershipDetails.creatorId, membershipDetails.creatorSetId)
        }
      />
    ));

  const unsaveMenuItems = creatorSetMembershipDetails
    .filter((membershipDetails) => membershipDetails.isSaved)
    .map((membershipDetails) => (
      <UnsaveCreatorItem
        key={`${membershipDetails.creatorId}-${membershipDetails.creatorSetId}`}
        loggingMetadata={loggingMetadata}
        creatorId={membershipDetails.creatorId}
        creatorSetId={membershipDetails.creatorSetId}
        creatorSetName={membershipDetails.name}
        unsave={() =>
          handleUnsaveCreatorId(membershipDetails.creatorId, membershipDetails.creatorSetId)
        }
      />
    ));

  return (
    <>
      {(saveMenuItems.length > 0 ||
        (saveMenuItems.length === 0 && unsaveMenuItems.length === 0)) && (
        <Menu.Label>Save to Creator Set</Menu.Label>
      )}
      {saveMenuItems}
      {saveMenuItems.length > 0 && unsaveMenuItems.length > 0 && <Menu.Divider />}
      {unsaveMenuItems.length > 0 && <Menu.Label>Unsave from Creator Set</Menu.Label>}
      {unsaveMenuItems}
      {handleAddCreatorSet && (
        <>
          <Menu.Divider />
          <Menu.Item
            leftSection={<IconCirclePlus size="1rem" />}
            onClick={openAddNewCreatorSetModal}>
            <Text fw="500" size="sm">
              New Creator Set
            </Text>
          </Menu.Item>
        </>
      )}
    </>
  );
}

export default function CreatorResultControls({
  creatorId,
  creatorSetMembershipDetails,
  hiddenCreatorIdsSet,
  handleActivate,
  handleSave,
  handleUnsave,
  handleHide,
  handleUnhide,
  loggingMetadata,
  handleSeeRelated,
  handleAddCreatorSet,
  handleReject,
}: {
  creatorId: number;
  creatorSetMembershipDetails: CreatorSetMembershipDetails[];
  hiddenCreatorIdsSet: Set<number>;
  handleActivate: (creatorId: number, creatorSetId: number) => void;
  handleSave: (creatorId: number, creatorSetId: number) => void;
  handleUnsave: (creatorId: number, creatorSetId: number) => void;
  handleHide: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  handleUnhide: (
    value: number,
    setLoading: (loading: boolean) => void,
    loggingMetadata?: LoggingMetadata,
  ) => void;
  loggingMetadata?: LoggingMetadata;
  handleSeeRelated?: () => void;
  handleAddCreatorSet?: (creatorSetId: number, creatorSetName: string) => void;
  handleReject?: (creatorId: number, creatorSetId: number) => void;
}) {
  const seeRelatedButton = handleSeeRelated ? (
    <Button
      size="xs"
      variant="gradient"
      leftSection={<IconUsers size="1.1rem" />}
      gradient={{ from: "indigo", to: "cyan", deg: 135 }}
      onClick={handleSeeRelated}>
      Related Creators
    </Button>
  ) : null;

  const [hideLoading, setHideLoading] = useState(false);

  const isActivated = creatorSetMembershipDetails.some(
    (membershipDetails) => membershipDetails.isActivated,
  );
  const isSaved = creatorSetMembershipDetails.some(
    (membershipDetails) => membershipDetails.isSaved,
  );
  const isHidden = !isActivated && hiddenCreatorIdsSet.has(creatorId);

  const activateMenuItems = creatorSetMembershipDetails.map((membershipDetails) => (
    <ActivateCreatorItem
      key={`${membershipDetails.creatorId}-${membershipDetails.creatorSetId}`}
      loggingMetadata={loggingMetadata}
      membershipDetails={membershipDetails}
      handleActivateCreatorId={handleActivate}
      onCreatorRejected={handleReject ?? (() => {})}
    />
  ));

  const dispatch = useAppDispatch();
  const [
    addNewCreatorSetModalOpened,
    { open: openAddNewCreatorSetModal, close: closeAddNewCreatorSetModal },
  ] = useDisclosure();

  return (
    <>
      {handleAddCreatorSet && (
        <AddCreatorSetModal
          handleAddNewCreatorSet={(creatorSetItem: CreatorSetItem) => {
            dispatch(addNewCreatorSetToState({ creatorSet: creatorSetItem }));
            handleAddCreatorSet(creatorSetItem.id, creatorSetItem.name);
          }}
          opened={addNewCreatorSetModalOpened}
          close={closeAddNewCreatorSetModal}
        />
      )}
      <Flex gap="xs" align="flex-start">
        {!isHidden && (
          <Menu trigger="hover" closeOnItemClick={false}>
            <Menu.Target>
              <Button
                size="xs"
                color={isActivated && "teal"}
                leftSection={isActivated && <IconCheck size="1rem" />}>
                {isActivated ? "Activated" : "Activate"}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Label>Activate for Creator Set</Menu.Label>
              {activateMenuItems}
              {handleAddCreatorSet && (
                <>
                  <Menu.Divider />
                  <Menu.Item
                    leftSection={<IconCirclePlus size="1rem" />}
                    onClick={openAddNewCreatorSetModal}>
                    <Text fw="500" size="sm">
                      New Creator Set
                    </Text>
                  </Menu.Item>
                </>
              )}
            </Menu.Dropdown>
          </Menu>
        )}
        {!isHidden && (
          <Menu trigger="hover" closeOnItemClick={false}>
            <Menu.Target>
              <Button size="xs" variant={!isSaved && "light"} color={isSaved && "teal"}>
                {isSaved ? <IconBookmarks size="1.1rem" /> : <IconBookmarkPlus size="1.1rem" />}
              </Button>
            </Menu.Target>
            <Menu.Dropdown>
              <SaveMenu
                loggingMetadata={loggingMetadata}
                creatorSetMembershipDetails={creatorSetMembershipDetails}
                handleSaveCreatorId={handleSave}
                handleUnsaveCreatorId={handleUnsave}
                openAddNewCreatorSetModal={openAddNewCreatorSetModal}
                handleAddCreatorSet={handleAddCreatorSet}
              />
            </Menu.Dropdown>
          </Menu>
        )}
        {!isActivated && !isSaved && (
          <Tooltip label={isHidden ? "Unhide Creator" : "Hide Creator"} position="top">
            <Button
              size="xs"
              variant="default"
              loading={hideLoading}
              onClick={() => {
                if (isHidden) {
                  handleUnhide(creatorId, setHideLoading, loggingMetadata);
                } else {
                  handleHide(creatorId, setHideLoading, loggingMetadata);
                }
              }}>
              {isHidden ? <IconEye size="1.1rem" /> : <IconEyeOff size="1.1rem" />}
            </Button>
          </Tooltip>
        )}
        {handleSeeRelated && <Divider orientation="vertical" />}
        {seeRelatedButton}
      </Flex>
    </>
  );
}
